import React from 'react';
import './style.less';
import {Button, Form, Input, Modal} from 'antd';
import {MyTripContext} from "../../context/MyTripContext";
import language from "../../../data/language.yaml";
import {minutesToTime, metresToMiles} from "../../services/helpers";
import {sendItineraryEmail} from "../../services/api";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Link from "../Link";
import {trackWebsiteClick, trackBookingClick, trackViewMember} from "../../services/gtm";
import DiscoverSnowdoniaMap from "../DiscoverSnowdoniaMap";

class MyTripOverview extends React.Component {

    constructor(props, context){
        super(props);
        this.state = {
            directions: false,
            error: '',
            mapModalVisible: false,
            success: '',
            emailTo: ''
        }
    }

    validateEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };

    sendEmail = () => {
        let trip = this.context.tripData;
        // Check we have itinerary items
        if(trip[0].length < 2){
            return this.setState({
                error: language.myTrip.addMoreItems[this.props.locale]
            });
        }

        // Add the email to the data
        if(this.state.emailTo === ''){
            return this.setState({
                error: language.myTrip.enterEmail[this.props.locale]
            });
        }

        // Check this is an email address
        if(this.validateEmail(this.state.emailTo)!==true){
            return this.setState({
                error: language.myTrip.enterEmail[this.props.locale]
            });
        }

        // Send the request
        sendItineraryEmail(trip, this.state.emailTo, this.props.locale, (error, success) => {
            this.setState({
                error: '',
                success: language.myTrip.emailSent[this.props.locale]
            })
        });
    };

    getDirectionProperties = (dayNumber, startIndex) => {
        let properties = {
            origin: false,
            destination: false,
            waypoints: []
        };
        this.context.tripData.map((day, dayIndex) => {
            day.map((location, locationIndex) => {
                if (dayNumber === false || dayNumber === dayIndex) {
                    if (startIndex === false || locationIndex === startIndex || locationIndex === startIndex + 1) {
                        if (location.hasOwnProperty("latitude") && location.hasOwnProperty("longitude")) {
                            properties.waypoints.push(location.latitude + "," + location.longitude);
                        }
                    }
                }
                return null;
            });
            return null;
        });
        if (properties.waypoints.length > 1) {
            properties.origin = properties.waypoints.shift();
            properties.destination = properties.waypoints.pop();
        } else {
            properties = false;
        }
        return properties;
    }

    getDirectionsURL = (dayNumber) => {
        let url = "https://www.google.com/maps/dir/?api=1";
        let properties = this.getDirectionProperties(dayNumber);
        if (properties.origin && properties.destination) {
            url += "&origin=" + properties.origin;
            url += "&destination=" + properties.destination;
        }

        if (properties.waypoints.length > 25) {
            return false;
        } else if (properties.waypoints.length > 0) {
            url += "&waypoints=" + properties.waypoints.join("|");
        }
        return url;
    }

    onOpenDirectionsMap = (dayNumber, startIndex) => {
        let properties = this.getDirectionProperties(dayNumber, startIndex);
        let directions = {
            destination: properties.destination,
            origin: properties.origin,
            travelMode: "DRIVING"
        };
        if (properties.waypoints) {
            directions.waypoints = [];
            properties.waypoints.map((waypoint, index) => {
                directions.waypoints.push({
                    location: waypoint,
                    stopover: true
                })
                return null;
            });
        }

        this.setState({directions: directions, mapModalVisible: true});
    }

    onCloseDirectionsMap = () => {
        this.setState({mapModalVisible: false});
    }

    render() {
        let trip = this.context.tripData;
        let lang = language.member;

        let entireRouteBox = "";
        let completeDirections = this.getDirectionProperties(false, false);
        if (completeDirections) {
            entireRouteBox = <div className="view-entire-route">
                <div className="inner">
                    <h2>{language.myTrip.viewRoute.complete.title[this.props.locale]}</h2>
                    <Button className="btn btn-white directions btn-small" onClick={() => this.onOpenDirectionsMap(false, false) }>
                        <span>{language.myTrip.viewRoute.complete.button[this.props.locale]}</span>
                        <span className="icon arrow"/>
                    </Button>
                </div>
            </div>
        }

        let emailTripBox = <div className="email-trip">
            <h2>{language.myTrip.sendEmail.title[this.props.locale]}</h2>
            <Form.Item name={['user', 'email']} label="" rules={[{ type: 'email' }]}>
                {this.state.error === '' ? '' :
                    <Input className="error" placeholder={language.contactUs.emailAddress[this.props.locale]} value={this.state.emailTo} onChange={(e) => {this.setState({ emailTo: e.target.value })}}/>
                }
                {this.state.error !== '' ? '' :
                    <Input placeholder={language.contactUs.emailAddress[this.props.locale]} value={this.state.emailTo} onChange={(e) => {this.setState({ emailTo: e.target.value })}}/>
                }
            </Form.Item>
            {this.state.error === '' ? '' :
                <p className={'error'}>{this.state.error}</p>
            }
            <Link onClick={this.sendEmail} className="btn btn-green btn-small"><span>{language.myTrip.sendEmail[this.props.locale]}</span><span className="icon arrow" /></Link>
            {this.state.success === '' ? '' :
                <p className={'success'}>{this.state.success}</p>
            }
        </div>

        return (
            <React.Fragment>
                <div className="my-trip-overview-intro">
                    <div className="my-trip-intro">
                        <div dangerouslySetInnerHTML={{__html: this.props.content}} />
                    </div>
                    <div className="route-box rb-top">
                        { entireRouteBox }
                        { emailTripBox }
                    </div>
                </div>

                <Modal
                    className="my-trip-map-modal"
                    title={null}
                    visible={this.state.mapModalVisible}
                    footer={null}
                    closable={true}
                    onCancel={this.onCloseDirectionsMap}
                    options={{
                        enableEventPropagation: true,
                        pixelOffset:            {width: 0, height: -300}
                    }}
                >
                    <span className="content-wrap">
                        <DiscoverSnowdoniaMap locale={this.props.locale} showFilterPrompt={false} showRoute={false} directions={this.state.directions}/>
                        <div id="directionsText"></div>
                    </span>
                </Modal>

                {trip.map((day, dayIndex) => {

                    // Get the daily total distance and time
                    let dayDistance = 0;
                    let dayMinutes = 0;
                    trip[dayIndex].map(location => {
                        if(location.route !== null && typeof location.route !=='undefined') {
                            dayDistance += location.route.distance;
                            dayMinutes += location.route.duration / 60;
                        }
                        return null;
                    });

                    let dailyTotal = <p className="daily-total"><span className="distance">{metresToMiles(dayDistance, this.props.locale)}</span><span className="time">{minutesToTime(dayMinutes, this.props.locale)}</span></p>;

                    return (
                        <div className="day-panel" key={`day_${dayIndex}`}>
                            <div className="day-title">
                                <h2>{language.myTrip.day[this.props.locale]} {dayIndex+1}</h2>
                                {dailyTotal}
                                <Button className="btn btn-white btn-small directions" onClick={() => this.onOpenDirectionsMap(dayIndex, false) }>
                                    <span>{language.myTrip.viewRoute.day[this.props.locale]}</span>
                                    <span className="icon arrow"/>
                                </Button>
                            </div>
                            {day.map((location, locIndex) => {
                                // check for start location
                                if(dayIndex===0 && locIndex===0){
                                    return (
                                        <h3 key={locIndex}>{language.myTrip.startingLocation[this.props.locale]}: {location.text}</h3>
                                    )
                                }

                                // Set the route info if this is not the first location of the day
                                let routeInformation = '';
                                if(locIndex > 0){
                                    // Check we have a route first
                                    if(location.routeIndex!==null) {
                                        // Set the maps link to get a route
                                        // let route = location.routeIndex.split(';');
                                        // let origin = route[0].split(',');
                                        // let destination = route[1].split(',');
                                        // let mapsLink = `https://www.google.com/maps/dir/?api=1&origin=${origin[1]},${origin[0]}&destination=${destination[1]},${destination[0]}`;
                                        routeInformation = (
                                            <div key={`loc_${locIndex}`}>
                                                <p className={'routeInfo'}>
                                                    <span>{metresToMiles(location.route.distance, this.props.locale)} / {minutesToTime(location.route.duration / 60, this.props.locale)}</span>
                                                </p>
                                                <Button className="btn btn-green btn-small directions" onClick={() => this.onOpenDirectionsMap(dayIndex, locIndex) }>
                                                    <span>{language.myTrip.getDirections[this.props.locale]}</span>
                                                    <span className="icon arrow"/>
                                                </Button>
                                            </div>
                                        );
                                    }
                                }

                                let address = location.name;
                                if(location.address_1) address += `, ${location.address_1}`;
                                if(location.address_2) address += `, ${location.address_2}`;
                                if(location.county) address += `, ${location.county}`;
                                if(location.town) address += `, ${location.town}`;
                                if(location.postcode) address += `, ${location.postcode}`;

                                // Set the member link, this is the path for most, the website for level 3 members
                                let link = `${location.member.path}?location=${location.wordpress_id}`;
                                if(location.member.level === 3){
                                    link = location.member.website_url;
                                }

                                return (
                                    <React.Fragment key={"day-" + dayIndex + "-loc-" + locIndex}>
                                        {routeInformation}
                                        <div className={'overviewItem'}>
                                            <img src={location.member.featured_image.sizes.large} alt=""/>
                                            <div className="con-wrap">
                                                <Link to={link} onClick={() => trackViewMember(link)}><h4>{location.name}</h4></Link>
                                                <p>{address}</p>
                                                <p>{location.member.snippet}</p>
                                                <div className="location-actions">
                                                    <Link className="btn btn-orange btn-small" to={location.member.booking_url} onClick={() => trackBookingClick(location.member.acf.booking_url)}>
                                                        <span>{lang.bookOnline[this.props.locale]}</span>
                                                        <span className="icon arrow"></span>
                                                    </Link>
                                                    <Link className="btn btn-white btn-small" to={location.member.website_url} onClick={() => trackWebsiteClick(location.member.acf.website_url)}>
                                                        <span>{lang.visitWebsite[this.props.locale]}</span>
                                                        <span className="icon arrow"></span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    )
                })}

                <div className="route-box rb-bottom">
                    { entireRouteBox }
                    { emailTripBox }
                </div>
            </React.Fragment>
        );
    }
}

MyTripOverview.contextType = MyTripContext;

export default MyTripOverview
